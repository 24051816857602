/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.12,
* Autoprefixer: v10.4.4
* Browsers: last 4 version
*/

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fleur+De+Leah&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Elsie+Swash+Caps&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;900&display=swap');

:root{
  --defaultFont: 'Montserrat', sans-serif;
  --defaultShadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
  --on: rgba(172, 216, 235);
	--off: #f0f0f0;
	--transDur: 0.2s;
}

/*********************************************************************** 
********* Base ******************************************
*********** Styles ********************************
******************************************/
*, :after, :before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  background: #dde1e7;
  font-family: var(--defaultFont);
  color: var(--secondary);
}
a{
  text-decoration: none;
  cursor: pointer;
}
p{
  font-family: "Noto Serif", serif;
}
h1{
  margin: 0 0;
}
span {
  font-weight: bolder;
}
.App{
  /* max-width: 960px; */
  background: rgb(255,255,255);
background: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,1)), color-stop(0%, rgb(255, 255, 255)), color-stop(40%, rgba(221,225,231,1)));
background: -o-linear-gradient(left, rgba(255,255,255,1) 0%, rgb(255, 255, 255) 0%, rgba(221,225,231,1) 40%);
background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgb(255, 255, 255) 0%, rgba(221,225,231,1) 40%);
  margin: 0 auto;
}
/********************************************************************** 
********* Title ******************************************
*********** Styles ********************************
******************************************/
.banner {
  position: relative;
  background: url('/src/img/blossoms.png');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: bottom right;
  background-attachment: fixed;
  overflow: hidden;
  width: 100%;
  height: 465px;
  /* max-height: 600px;
  max-width: 900px; */
  
  text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
}
.foreground {
 background-image: url('./img/TW-mobileBanner.png');
 background-repeat: no-repeat;
 background-size: contain;
 background-position: bottom;
 position: absolute;
 width: 350px;
 height: 600px;
 
 bottom: -100px;
 left: 30%;
}
.mandala {
  background: url('./img/mandala-blu.png') no-repeat center;
  position: relative;
  background-size: contain;
  /* top: 10%; */
  /* left: 0; */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* margin-top: 50px; */
}
.title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 2rem;
  color: var(--primary);
  font-family: 'Fleur De Leah', cursive;
  text-shadow: -5px -5px 5px rgba(255,255,255,.1), 5px 5px 5px rgba(0,0,0,.9);
  font-size: 4.2rem;
  letter-spacing: 2px;
  font-weight:550;
  text-align: center;
}
.sub-heading{
  font-family: var(--defaultFont);
  text-shadow: #bab3b3 2px 2px 0;
  color: rgb(130, 178, 181);
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1rem;
}
.welcome-box {
  background: rgba(172, 216, 235);
  color: #4293bb;
  text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
}
.autoplay-notification {
  background: none;
  color: whitesmoke;
  
}
.welcome-box,
.autoplay-notification {
  font-family: var(--defaultFont);
  position: fixed;
  top: 5%;
  left: -80%;
  /* border: 1px solid black; */
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-duration: 5s;
          animation-duration: 5s; 
  -webkit-animation-timing-function: ease-in-out; 
          animation-timing-function: ease-in-out; 
  -webkit-animation-delay: 0; 
          animation-delay: 0;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: none;
          animation-fill-mode: none;
 width: 40%;
 padding: .5rem 1rem;
 z-index: 10;
 
}
/*********************************************************************** 
********* Menu ******************************************
*********** Styles ********************************
******************************************/
.menu {
  position: fixed;
  /* display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center; */
  top: 0;
  right: 0;
  list-style: none;
  /* margin: 20px 5px 0 0; */
  z-index: 8;
}
.menu-button {
  /* background: url('/src/img/tree.svg') center no-repeat; */
  background-color: #dde1e7;
  background-size: 3rem;
  /* padding-bottom: 75px;
  height: 60px; */
}
.login-btn,
.logout-btn {
  background-color: #dde1e7;
  background-size: 3rem;
  width: 100%;
}

.li-space {
  margin-top: 1rem;
}
li {
  border: 1;
  -webkit-box-shadow: -5px -5px 9px rgba(255,255,255,0.45), 5px 5px 9px rgba(94,104,121,0.3);
          box-shadow: -5px -5px 9px rgba(255,255,255,0.45), 5px 5px 9px rgba(94,104,121,0.3);
  position: relative;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  z-index: 10;
  /* width: 4rem;
  height: 60px; */
}
li:hover{
  color: #fefefe;
  -webkit-box-shadow: inset -5px -5px 9px rgba(255,255,255,0.45), inset 5px 5px 9px rgba(94,104,121,0.3);
          box-shadow: inset -5px -5px 9px rgba(255,255,255,0.45), inset 5px 5px 9px rgba(94,104,121,0.3);
  font-weight: bold;
}
.upload-button {
  background-color: #dde1e7;
  background-size: 3rem;
  cursor: pointer;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  z-index: 10;
}

.upload-label {
  cursor: pointer;
  position: absolute;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  width: 60px;
  z-index: 10;
}

/*********************************************************************** 
********* Login/Sign-Up ******************************************
*********** Form Styles ********************************
******************************************/
.close-form-btn {
  background: url('./img/close-btn2.svg') center no-repeat;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0%;
  padding: 10px;
}
.login-signup-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 15;
}
.login-divider {
  background-color: #000000;
  height: 3px;
  margin-bottom: 1rem;
  width: 100%;
}
.login-html {
  position: relative;
  background: rgba(172, 216, 235);
  -webkit-box-shadow: var(--defaultShadow);
          box-shadow: var(--defaultShadow);
  width: 100%;
  min-height: 600px;
  padding: 2rem 1.2rem 4rem 1.2rem;
  z-index: 10;
}
.login-html .sign-in-htm,
.login-html .sign-up-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:absolute;
	-webkit-transform:rotateY(180deg);
	        transform:rotateY(180deg);
	-webkit-backface-visibility:hidden;
	        backface-visibility:hidden;
	-webkit-transition:all .4s linear;
	-o-transition:all .4s linear;
	transition:all .4s linear;
}
.login-html .sign-in,
.login-html .sign-up,
.login-form .group .check{
	display:none;
}
.login-html .tab,
.login-form .group .label,
.login-form .group .button,
.button,
.year-input-label,
.login-btn {
	text-transform:uppercase;
}
.login-html .tab{
	font-size:22px;
	margin-right:15px;
	padding-bottom:5px;
	margin:0 15px 10px 0;
	display:inline-block;
	border-bottom:2px solid transparent;
}
.login-html .sign-in:checked + .tab,
.login-html .sign-up:checked + .tab{
	color:#fff;
	border-color:#1161ee;
}
.login-form{
	min-height:345px;
	position:relative;
	-webkit-perspective:1000px;
	        perspective:1000px;
	-webkit-transform-style:preserve-3d;
	        transform-style:preserve-3d;
}
.login-form .group{
	margin-bottom:15px;
}
.button {
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button{
	width:100%;
	color:#fff;
	display:block;
  margin-bottom: .5rem;
}

.login-form .group .input,
.login-form .group .button{
	border:none;
	padding:15px 20px;
	border-radius:25px;
	background:rgba(35, 130, 212, 0.502);
}
.form-login-btn {
  margin-top: 30px;
  font-family: 'Montserrat', sans-serif;
}
.login-form .group input[data-type="password"]{
	text-security:circle;
	-webkit-text-security:circle;
}
.login-form .group .label,
.year-input-label {
	color:rgb(21, 19, 19);
	font-size:12px;
}
.login-form .group .button{
	background:#1161ee;
}
.login-form .group .button:hover{
	background:#450da6;
}
.login-form .group label .icon{
	width:15px;
	height:15px;
	border-radius:2px;
	position:relative;
	display:inline-block;
	background:rgba(255,255,255,.1);
}
.login-form .group label .icon:before,
.login-form .group label .icon:after{
	content:'';
	width:10px;
	height:2px;
	background:#fff;
	position:absolute;
	-webkit-transition:all .2s ease-in-out 0s;
	-o-transition:all .2s ease-in-out 0s;
	transition:all .2s ease-in-out 0s;
}
.login-form .group label .icon:before{
	left:3px;
	width:5px;
	bottom:6px;
	-webkit-transform:scale(0) rotate(0);
	    -ms-transform:scale(0) rotate(0);
	        transform:scale(0) rotate(0);
}
.login-form .group label .icon:after{
	top:6px;
	right:0;
	-webkit-transform:scale(0) rotate(0);
	    -ms-transform:scale(0) rotate(0);
	        transform:scale(0) rotate(0);
}
.login-form .group .check:checked + label{
	color:#fff;
}
.login-form .group .check:checked + label .icon{
	background:#1161ee;
}
.login-form .group .check:checked + label .icon:before{
	-webkit-transform:scale(1) rotate(45deg);
	    -ms-transform:scale(1) rotate(45deg);
	        transform:scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after{
	-webkit-transform:scale(1) rotate(-45deg);
	    -ms-transform:scale(1) rotate(-45deg);
	        transform:scale(1) rotate(-45deg);
}
.login-html .sign-in:checked + .tab + .sign-up + .tab + .login-form .sign-in-htm{
	-webkit-transform:rotate(0);
	    -ms-transform:rotate(0);
	        transform:rotate(0);
}
.login-html .sign-up:checked + .tab + .login-form .sign-up-htm{
	-webkit-transform:rotate(0);
	    -ms-transform:rotate(0);
	        transform:rotate(0);
}
.verify-caption {
  margin: 1rem auto;
  font-size: 1rem;
}
.resetPassLink {
  background-color: transparent;
  color: #18068b;
  border: none;
  cursor: pointer;
  outline: 0;
  font-size: small;
  text-align: left;
  margin-left: 6px;
  width: 100%;
}
.resetPassLink:hover {
  text-decoration: underline;
}
.password-reset-form {
  margin-top: 3rem;
}
.recaptcha {
  height: auto;
  width: 100%;
}
.error-msg {
  color: red;
  text-align: center;
}
.legal {
  background-color: transparent;
  color: #18068b;
  border: none;
  outline: 0;
  font-size: small;
  text-align: center;
  margin-left: 6px;
  width: 100%;
}
/*********************************************************************** 
********* Upload ******************************************
*********** Form Styles ********************************
******************************************/
form{
  position: absolute;
  left: 0;
  /* margin: 30px auto 10px; */
  text-align: center;
}
label input{
  height: 0;
  width: 0;
  opacity: 0;
}
.upload-divider {
  background-image: -webkit-gradient(linear, left top, right top, from(#80b6d5), color-stop(#5cb5d8), to(#75bfda));
  background-image: -o-linear-gradient(left, #80b6d5, #5cb5d8, #75bfda);
  background-image: linear-gradient(to right, #80b6d5, #5cb5d8, #75bfda);
  border: none;
  height: 5px;
  width: 90%;margin-left: auto;margin-right: auto;margin-bottom: 4rem;
}
.output{
  background-color: rgba(172, 216, 235);
  -webkit-box-shadow: var(--defaultShadow);
          box-shadow: var(--defaultShadow);
  position: fixed;
  top: 5%;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.file-name {
  font-family: var(--defaultFont);
  text-align: center;
  font-size: 18px;
}
.error{
  color: var(--error);
  text-align: center;
  font-weight: bold;
  position: fixed;
  top: 5%;
  left: -80%;
  border: 1px solid black;
  padding: 1rem;
  background: rgba(172, 216, 235);
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-duration: 5s;
          animation-duration: 5s; 
  -webkit-animation-timing-function: ease-in-out; 
          animation-timing-function: ease-in-out; 
  -webkit-animation-delay: 0; 
          animation-delay: 0;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: none;
          animation-fill-mode: none;
}
/*********************************************************************** 
********* Progress Bar ******************************************
************** Styles ********************************
******************************************/
/* .progress-bar {
  position: absolute;
  left: 40%;
  height: 1rem;
  content: "";
  background-color: #54b2e8;
  border-radius: inherit;
  color: white;
}
.progress-container {
  position: relative;
  margin: 1rem 5rem;
  height: 2rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  transition: all 0.5s;
  padding: 0 3rem;
  width: 50%;
} */
/*********************************************************************** 
********* Image Caption ******************************************
*********** Form Styles ********************************
******************************************/
.desc-form {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: fixed;
  top: 20%;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: rgba(172, 216, 235);
  -webkit-box-shadow: var(--defaultShadow);
          box-shadow: var(--defaultShadow);
  margin: 0 auto;
  padding: 2rem;
  min-width: 300px;
  /* max-width: 460px; */
  width: 100%;
  z-index: 15;
}
.desc-input,
.year-input {
  font-family: 'noto-serif', serif;
  font-size: 1.1rem;
  border: none;
  padding: 15px 20px;
  border-radius: 25px;
  background: rgba(35, 130, 212, 0.502);
  color: white;
}
.desc-input {
  min-width: 245px;
  min-height: 117px;
  margin-bottom: 1rem;
}
.year-input-label {
  margin-top: 30px;
  text-align: center;
  font-size: .9rem;
}
.year-input {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  width: 90px;
  padding: 5px;
  margin: 20px 0;
}
.desc-form button {
  border:none;
	padding:15px 20px;
	border-radius:25px;
	background:#1161ee;
  color: white;
}
.desc-form span {
  font-size: 1rem;
  margin: 1rem auto;
}

/*********************************************************************** 
********* Photo Toggle ******************************************
*********** Switch Styles ********************************
******************************************/
.toggle-container {
  display: inline-block;
  position: fixed;
  width: 20%;
  z-index: 10;
}
.toggle-input {
	position: fixed;
	-webkit-transform: translateX(-100%);
	    -ms-transform: translateX(-100%);
	        transform: translateX(-100%);
}
.toy-toggle {
	/* background:
		radial-gradient(at top left,#fff 10%,#fff0 20%),
		radial-gradient(at top right,#fff 20%,#e4e4e4 35%); */
	border-radius: 6em;
	/* box-shadow:
		0 0 0.25em #0002,
		0 3em 1.5em 0.5em #0002; */
	cursor: pointer;
	display: block;
	font-size: 2px;
	position: relative;
	width: 20em;
	height: 12em;
  margin: 1rem 1rem;
	-webkit-tap-highlight-color: transparent;
}
.toy-toggle span {
	display: block;
	position: absolute;
}
.toy-toggle > span {
	top: 50%;
	left: 50%;
}
.toy-toggle > span:not(.handle) {
	-webkit-transform: translate(-50%,-50%);
	    -ms-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
}
.border1 {
	background: #f0f0f0;
	border-radius: 5.5em;
	-webkit-box-shadow: 0 0 0.2em 0.1em #f0f0f0;
	        box-shadow: 0 0 0.2em 0.1em #f0f0f0;
	width: 19em;
	height: 11em;
}
.border2 {
	background: -webkit-gradient(linear,left bottom, left top,color-stop(33%, #fff),color-stop(45%, #ccc));
	background: -o-linear-gradient(bottom,#fff 33%,#ccc 45%);
	background: linear-gradient(0deg,#fff 33%,#ccc 45%);
	border-radius: 4.75em;
	-webkit-box-shadow: 0 0 0.2em 0.3em #f0f0f0 inset;
	        box-shadow: 0 0 0.2em 0.3em #f0f0f0 inset;
	width: 16.5em;
	height: 9.5em;
}
.border3, .handle {
	background: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--on)), color-stop(0, var(--off)));
	background: -o-linear-gradient(left, var(--on) 50%, var(--off) 0);
	background: linear-gradient(90deg, var(--on) 50%, var(--off) 0);
}
.border3 {
	background-position: 75% 0;
	background-size: 200% 100%;
	border-radius: 4.25em;
	-webkit-box-shadow:
		0 0 0.1em 0.1em #ddd inset,
		0 1.5em 1.5em 1em #0004 inset,
		0 0 0 4.25em #0002 inset;
	        box-shadow:
		0 0 0.1em 0.1em #ddd inset,
		0 1.5em 1.5em 1em #0004 inset,
		0 0 0 4.25em #0002 inset;
	width: 15.5em;
	height: 8.5em;
	-webkit-transition: background-position var(--transDur) ease-in-out;
	-o-transition: background-position var(--transDur) ease-in-out;
	transition: background-position var(--transDur) ease-in-out;
}
.handle {
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0.5em 0 #0007;
	        box-shadow: 0 0 0.5em 0 #0007;
	width: 8.5em;
	height: 8.5em;
	-webkit-transform: translate(-90%,-50%);
	    -ms-transform: translate(-90%,-50%);
	        transform: translate(-90%,-50%);
	-webkit-transition: -webkit-transform var(--transDur) ease-in-out;
	transition: -webkit-transform var(--transDur) ease-in-out;
	-o-transition: transform var(--transDur) ease-in-out;
	transition: transform var(--transDur) ease-in-out;
	transition: transform var(--transDur) ease-in-out, -webkit-transform var(--transDur) ease-in-out;
	z-index: 0;
}
.handle:before {
	background:
		-o-radial-gradient(50% 35%,2em 1.5em,#fff6 15%,#fff0),
		-o-radial-gradient(75% 40%,1.5em 2.5em,#fff6 15%,#fff0),
		-o-radial-gradient(50% 33%,100% 100%,#0000 25%,#0003 50%);
	background:
		radial-gradient(2em 1.5em at 50% 35%,#fff6 15%,#fff0),
		radial-gradient(1.5em 2.5em at 75% 40%,#fff6 15%,#fff0),
		radial-gradient(100% 100% at 50% 33%,#0000 25%,#0003 50%);
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0.3em 0.1em #0003 inset;
	        box-shadow: 0 0 0.3em 0.1em #0003 inset;
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
}
.handle-off, .handle-on {
	width: 50%;
	height: 100%;
	-webkit-transition: -webkit-transform calc(var(--transDur)/2);
	transition: -webkit-transform calc(var(--transDur)/2);
	-o-transition: transform calc(var(--transDur)/2);
	transition: transform calc(var(--transDur)/2);
	transition: transform calc(var(--transDur)/2), -webkit-transform calc(var(--transDur)/2);
	z-index: 2;
}
.handle-off {
	background: var(--off);
	border-radius: 100% 0 0 100% / 50% 50% 50% 50%;
	right: 50%;
	-webkit-transform-origin: 100% 50%;
	    -ms-transform-origin: 100% 50%;
	        transform-origin: 100% 50%;
	-webkit-transition-delay: calc(var(--transDur)/2);
	     -o-transition-delay: calc(var(--transDur)/2);
	        transition-delay: calc(var(--transDur)/2);
	-webkit-transition-timing-function: ease-out;
	     -o-transition-timing-function: ease-out;
	        transition-timing-function: ease-out;
}
.handle-on {
	background: var(--on);
	border-radius: 0 100% 100% 0 / 50% 50% 50% 50%;
	left: 50%;
	-webkit-transform: scaleX(0);
	    -ms-transform: scaleX(0);
	        transform: scaleX(0);
	-webkit-transform-origin: 0 50%;
	    -ms-transform-origin: 0 50%;
	        transform-origin: 0 50%;
	-webkit-transition-timing-function: ease-in;
	     -o-transition-timing-function: ease-in;
	        transition-timing-function: ease-in;
}

/* On */
input:checked + .toy-toggle .border3 {
	background-position: 25% 0;
}
input:checked + .toy-toggle .handle {
	-webkit-transform: translate(-10%,-50%);
	    -ms-transform: translate(-10%,-50%);
	        transform: translate(-10%,-50%);
}
input:checked + .toy-toggle .handle-off {
	-webkit-transform: scaleX(0);
	    -ms-transform: scaleX(0);
	        transform: scaleX(0);
	-webkit-transition-delay: 0s;
	     -o-transition-delay: 0s;
	        transition-delay: 0s;
	-webkit-transition-timing-function: ease-in;
	     -o-transition-timing-function: ease-in;
	        transition-timing-function: ease-in;
}
input:checked + .toy-toggle .handle-on {
	-webkit-transform: scaleX(1);
	    -ms-transform: scaleX(1);
	        transform: scaleX(1);
	-webkit-transition-delay: calc(var(--transDur)/2);
	     -o-transition-delay: calc(var(--transDur)/2);
	        transition-delay: calc(var(--transDur)/2);
	-webkit-transition-timing-function: ease-out;
	     -o-transition-timing-function: ease-out;
	        transition-timing-function: ease-out;
}
/*********************************************************************** 
********* Image Grid ******************************************
************ Styles ********************************
******************************************/
.img-grid{
  margin: 20px auto;
  /* max-width: 1000px; */
  width: 70%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 40px 1fr;
  grid-template-columns: 1fr 1fr ;
  grid-gap: 40px;
  z-index: 9;
}
.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  position: relative;
  opacity: 0.8;
  
}
.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
  
}
/*********************************************************************** 
********* Carousel ******************************************
*********** Styles ********************************
******************************************/

.arrow {
  background: url('./img/arrow.svg') center/contain  no-repeat;
  /* background-size: contain; */
  cursor: pointer;
  /* position: absolute; */
  bottom: 0;
  width: 90px;
  height: 90px;
  /* padding: 2rem; */
  z-index: 9;
}
.left-arrow {
  transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                
}
.arrow:hover,
.arrow:active {
  background: url('./img/arrow-filled.svg') center/contain no-repeat;
}
.carousel-container {
  background: rgba(0,0,0,.9);
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  /* flex-wrap: wrap; */
  /* justify-content: space-around; */
  margin: 0 auto;
  /* max-height: 500px; */
  height: 100%;
  width: 100%;
  z-index: 8;
}
.carousel-mandala {
  opacity: 0.25;
  background: url('./img/mandala-white.png') center no-repeat;
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  
}

.photo-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 5rem;
}
.photo-frame {
  position: relative;
  display: none;
  /* order: 1; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  padding-bottom: 75%;
  margin-bottom: 5rem;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
.photo-frame::before {
  -webkit-box-shadow: 100px 200px 195px #d35400;
          box-shadow: 100px 200px 195px #d35400;
}
.active {
  display: inline-block;
}
.carousel-year {
  font-family: 'Fleur De Leah', cursive;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -60px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
}
/*********************************************************************** 
********* AutoPlay ******************************************
*********** Switch ********************************
******************************************/
.autoplay-switch,
.caption-switch-container {
  /* border: .5px solid white; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: .2rem .5rem;
  position: absolute;
  top: 0;
  right:0;
  margin: .3rem;
  z-index: 10;
}
/* .play-label {
  background: url('../src/img/playpause.svg') center no-repeat;
  background-size: contain;
  width: 4px;
  height: 4px;
  margin-top: 5px;
  padding: 1rem;
} */
.autoplay,
.caption-switch {
  border: 1px solid white;
  margin:10px;
  position:relative;
  width:50px;
  height:25px;
  -webkit-appearance: none;
  background: -webkit-gradient(linear, left bottom, left top, from(#ddd), to(rgb(69, 68, 68)));
  background: -o-linear-gradient(bottom, #ddd, rgb(69, 68, 68));
  background: linear-gradient(0deg, #ddd, rgb(69, 68, 68));
  border-radius: 3px;
  /* box-shadow: 
  0 0 0.1em 0.1em rgb(255, 255, 255) inset, 
  0 1.5em 1.5em 1em #0004 inset, 
  0 0 0 4.25em #0002 inset; */
  outline: none; 
  /* box-shadow: inset 0px 4px 0px 0px rgb(0 0 0 / 20%), 
  1px 1px 0px 0px rgb(255, 255, 255), 
  inset 1px 1px 0 0 rgb(255, 255, 255); */
  -webkit-box-shadow: inset -1px 2px 5px rgb(0 0 0 / 80%), 0 1px 0 rgb(255 255 255 / 20%);
          box-shadow: inset -1px 2px 5px rgb(0 0 0 / 80%), 0 1px 0 rgb(255 255 255 / 20%);
}
.autoplay:checked,
.caption-switch:checked {
  background: -o-linear-gradient(100deg,var(--on) 70%, var(--on) 90%);
  background: linear-gradient(-10deg,var(--on) 70%, var(--on) 90%);
}
.autoplay::before {
  background: url('./img/playpause.svg')  center no-repeat,
  -webkit-gradient(linear, left top, left bottom, color-stop(10%, rgb(153, 153, 153)), color-stop(99%, #000000));
  background: url('./img/playpause.svg')  center no-repeat,
  -o-linear-gradient(rgb(153, 153, 153) 10%, #000000 99%);
  background: url('./img/playpause.svg')  center no-repeat,
  linear-gradient(rgb(153, 153, 153) 10%, #000000 99%);
  
}
.caption-switch::before {
  background: url('./img/comment.svg') center no-repeat,
  -webkit-gradient(linear, left top, left bottom, color-stop(10%, rgb(153, 153, 153)), color-stop(99%, #000000));
  background: url('./img/comment.svg') center no-repeat,
  -o-linear-gradient(rgb(153, 153, 153) 10%, #000000 99%);
  background: url('./img/comment.svg') center no-repeat,
  linear-gradient(rgb(153, 153, 153) 10%, #000000 99%);
}
.autoplay::before,
.caption-switch::before {
  content:'';
  position:absolute;
  width:1.6rem;
  height:1.4rem;
	border-radius: 5%;
  -webkit-box-shadow: 0px 1.5px 0px 0px #3c3c3c;
          box-shadow: 0px 1.5px 0px 0px #3c3c3c;
  /* box-shadow: 0 0 0 1px #232323; */
  -webkit-transform: scale(.96,.94);
      -ms-transform: scale(.96,.94);
          transform: scale(.96,.94);
  /* transform: skewX(-1deg); */
  
}
.autoplay:checked::before,
.caption-switch:checked::before {
  left: 23px;
  
}
/*********************************************************************** 
********* Modal ******************************************
*********** Styles ********************************
******************************************/
.caption-btn {
  background: rgba(172, 216, 235, 0.8);
  font-size: 12px;
  font-weight: 400;
  /* letter-spacing: 1px; */
  padding: 5px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  margin: 1rem;
  position: fixed;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0;
  text-align: center;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  width: 110px;
  z-index: 10;
}
.caption-btn:hover:active {
  background-color: #450da6;
  color: white;
}
.caption-switch-label {
  font-size: 15px;
  position: absolute;
  top: 0;
  right: .5rem;
  color: whitesmoke;
}
.caption-switch {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}
.backdrop{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  z-index: 10;
}
.pic-year-container {
  /* display: block; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.backdrop img{
  position: relative;
  max-width: 80%;
  max-height: 650px;
  margin-top: 100px;
  -webkit-box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
          box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}
.year {
  font-family: 'Fleur De Leah', cursive;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  /* position: fixed; */
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* align-items: center; */
  text-align: center;
  color: white;
  /* left: 50%; */
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  overflow: hidden;
  height: calc(100vh);
  
}
.caption-box {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: -o-radial-gradient(circle, rgba(10, 14, 14, 0.8) 0%, rgba(22, 28, 31, 0.8) 55%, transparent 100%) no-repeat;
  background: radial-gradient(circle, rgba(10, 14, 14, 0.8) 0%, rgba(22, 28, 31, 0.8) 55%, transparent 100%) no-repeat;
  border: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* top: 0; */
  bottom: 0;
  height: 0;
  width: 100%;
  max-width: 500px;
  text-align: center;
  color: white;
  z-index: 10;
  overflow-Y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
  /* -webkit-transform: translateX(-100%) scale(0.25); */
  -webkit-transition: all .5s;
  -webkit-transition: all .9s cubic-bezier(0.34, 1.56, 0.64, 1);
  -webkit-transition: all 1s cubic-bezier(0.34, 1.56, 0.64, 1);
  -o-transition: all 1s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: all 1s cubic-bezier(0.34, 1.56, 0.64, 1);
}
/* Hide scrollbar for Chrome, Safari and Opera */
.caption-box::-webkit-scrollbar {
  display: none;
}

.show-comments {
  border-color: whitesmoke;
  border-style:double;
  border-width: 2px 2px 0 2px;
  height: auto;
  max-height: 100%;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.caption-container {
  width: 100% ;
  height: 300px;
  color: white;
}
.blurred {
  -webkit-backdrop-filter:blur(5px);
          backdrop-filter:blur(5px);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
}
.post-blur-cont {
  position: relative;
  padding: .5rem;
  z-index: 10;
}
.comment,
.user,
.comment-date,
.caption {
  font-family: var(--defaultFont);
}
.caption {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.comment-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 10px; 
}
.comment {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  margin: 0 0 0 .5rem;
  padding-right: .5rem;
  text-align: left;
  color: white;
}
.user {
  margin: 2px 5px;
  font-size: .8rem;
  font-weight: bolder;
  color: rgb(191, 189, 189);
}
.comment-date {
  display: block;
  text-align: left;
  margin-left: .5rem;
  padding-top: .1rem;
  font-size: .8rem;
  font-weight: 400;
  color:rgb(160, 160, 172)
}
/*********************************************************************** 
********* Footer ******************************************
*********** Styles ********************************
******************************************/
.footer {
  font-family: var(--defaultFont);
  background-color: rgba(172, 216, 235, 0.8);
  margin: 0;
  padding: 1rem 0;
  text-align: center;
}

/*********************************************************************** 
********* Media ******************************************
*********** Queries ********************************
******************************************/

@media (max-width: 400px) {

  .banner {
    max-height: 500px;
  }
  
}

@media (min-width: 400px) {
  .title  {
    font-size: 5rem;
  }
  .sub-heading {
    font-size: 2rem;
  }
}
@media (min-width: 425px) {
  .caption-btn {
    padding: 8px 5px;
    width: 120px;
  }
}
@media (min-width: 568px) {
  .title  {
    margin: 0 auto;
    font-size: 6rem;
  }
  .caption-btn {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    width: 200px;
    outline: 0;
    margin: 1rem;
    position: fixed;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    left: 0;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    -webkit-user-select: none;
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    z-index: 10;
}
.mandala {
  height: 100%;
  width: 100%;
}
}
@media (min-width: 768px) {

  .banner {
    background: url('/src/img/background.png') no-repeat;
    background-size: cover;
    background-position: top;
    margin: 0 auto;
    /* height: 60vh; */
    /* width: 80%; */
    max-width: 1100px;
  }
  .title {
    font-size: 7.2rem;
    /* top: 0; */
  }
  .foreground {
    background: url('./img/fore-grnd-banner.png') bottom no-repeat;
    background-size: contain;
    width: 100%;
    /* height: 500px; */
    left: 0;
    bottom: -110px;
  }
  .mandala {
    display: none;
  }
  .toy-toggle {
    font-size: 3.5px;
  }
  .menu {
    margin-right: 1%;
  }
  li{
    width: 6rem;
  }
  .menu-button span,
  .login-btn span,
  .upload-button span{
    font-size: 1rem;
  }
  .logout-btn span {
    font-size: 1rem;
  }
  .login-signup-form {
    width: 80%;
    left: 10%;
  }
  .login-html {
    width: 70%;
    padding: 2rem 2.2rem 4rem 2.2rem;
  }
  .login-html .tab{
    font-size:22px;
  }
  .caption-switch-label {
    font-size: 18px;
  }
  .img-grid{
    margin: 20px auto;
    max-width: 1000px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 40px 1fr 40px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  }
  .desc-form {
    width: 50%;
    max-width: 435px;
  }
  .backdrop img {
    /* width: 60%; */
    margin-top: 0;
  }
  .scalePic {
    margin-top: 0;
    -webkit-transition: -webkit-transform .9s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition: -webkit-transform .9s cubic-bezier(0.34, 1.56, 0.64, 1);
    -o-transition: transform .9s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition: transform .9s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition: transform .9s cubic-bezier(0.34, 1.56, 0.64, 1), -webkit-transform .9s cubic-bezier(0.34, 1.56, 0.64, 1);
    -webkit-transform: scale(1, 1) translateX(-1rem);
        -ms-transform: scale(1, 1) translateX(-1rem);
            transform: scale(1, 1) translateX(-1rem);
  }
  .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    position: relative;
    overflow: hidden;
    height: calc(100vh);
    /* width: 100%; */
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    
  }
  .caption {
    font-size: 1.5rem;
  }
  .caption-box {
    border-bottom: 1px double white ;
    display: none;
    position: relative;
    margin-right: 2rem;
    width: 100%;
    -webkit-transform: translateX(100px);
        -ms-transform: translateX(100px);
            transform: translateX(100px);
  }
  .show-comments {
    display: inline-block;
    left: 0;
    right: 0;
    width: 100%;
    min-width: 400px;
    -webkit-animation-name: showComments;
            animation-name: showComments;
    -webkit-animation-duration: .9s;
            animation-duration: .9s; 
    -webkit-animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1); 
            animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1); 
    -webkit-animation-delay: 0; 
            animation-delay: 0;
    /* animation-direction: alternate; */
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode:forwards;
            animation-fill-mode:forwards;
  }
  .play-label {
    margin-top: 9px;
  }
  .autoplay,
  .caption-switch
  {
    width: 60px;
    height: 32px;
  }
  .autoplay::before,
  .caption-switch::before{
    top: .3px;
    height: 1.8rem;
    width: 1.8rem;
  }
  .autoplay:checked::before,
  .caption-switch:checked::before {
    left: 29px;
  }
  .left-arrow,
  .right-arrow {
    position: relative;
  }
  .photo-box {
    /* flex-wrap: nowrap; */
    /* justify-content: space-around; */
    -ms-flex-line-pack: distribute;
        align-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }
  .photo-frame {
    /* order: 2; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    padding-bottom: 75%;
    /* margin-left: .5rem;
    margin-right: .5rem; */
  }
  
}
@media (min-width: 915px) {
  .foreground {
    bottom: -120px;
  }
}


@media (min-width: 1160px) {
  .right-arrow,
  .left-arrow {
    /* margin: 5%; */
  }
}
/*********************************************************************** 
********* Keyframe ******************************************
*********** Animations ********************************
******************************************/
@-webkit-keyframes showComments {
  0% {
    display: inline-block;
    /* opacity: 0; */
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  5% {
    /* opacity: 0; */
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    display: inline-block;
    /* opacity: 1; */

  }
}
@keyframes showComments {
  0% {
    display: inline-block;
    /* opacity: 0; */
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  5% {
    /* opacity: 0; */
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    display: inline-block;
    /* opacity: 1; */

  }
}

@-webkit-keyframes fade {
  0% {  
    left: -80%
  }
  20% {
    left: 30%;
  }
  50%{
    left: 30%;
  }
  100% {
    left: -80%;
  }
}

@keyframes fade {
  0% {  
    left: -80%
  }
  20% {
    left: 30%;
  }
  50%{
    left: 30%;
  }
  100% {
    left: -80%;
  }
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

/* @-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
} */
